import { Validators } from "./validators";

export const getValidators = (schema, required) => {
  if (schema.type === 'integer') {
    return [Validators.isInteger()];
  }
  if (schema.type === "string" && typeof schema.default === "string") {
    const res = schema.default.split(`"`)[1];
    return [Validators.regex(res)];
  }
  if (required) {
    if (schema.type === "string" && schema.format === "uuid") {
      return [Validators.isUuid()];
    } else {
      return [Validators.required()];
    }
  }
};
