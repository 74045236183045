const generateCurlSnippet = ({ apiName, endpoint, data, operation }) => {
  const url = new URL(`${apiName}/${endpoint}`, process.env.GATSBY_API_ROOT);
  Object.entries(data.query).forEach(([key, value]) =>
    url.searchParams.append(key, value)
  );

  const headers = Object.entries(data.header)
    .filter(([key, value]) => !!value || key === 'X-Api-Key')
    .map(([key, value]) => `-H "${key}: ${value}"`);

  const body = Object.keys(data.body).length
    ? `-d '${JSON.stringify(data.body, null, 4)
        .split('\n')
        .map((line, i) => i === 0 ? line : '    ' + line)
        .join('\n')
        .replace(/'/g, "'\\''")}'`
    : undefined;

  const curlCommand = [
    `curl -X ${operation.toUpperCase()}`,
    `"${url}"`,
    ...headers,
    body,
  ]
    .filter(Boolean)
    .join(" \\\n");

  return curlCommand;
};

export default generateCurlSnippet;