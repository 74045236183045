import React, { useMemo } from "react";
import DateTimePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { string } from "prop-types";
import moment from "moment-timezone";

const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <Button onClick={onClick} ref={ref}>
    {value}
  </Button>
));

const DatePicker = ({ selected, onChange, disabled, ...props }) => {
  const selectedDate = useMemo(() => {
    if (selected) {
      return new Date(selected)
    }
    return null
  }, [selected])

  const fixLaDateParceQueMarcVeutPasLeFaire = (date) => {
    return moment(date).tz('Europe/Paris').format('YYYY-MM-DDTHH:mm:ss')
  }

  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <DateTimePicker
        // maxDate={moment().toDate()}
        wrapperClassName="date_picker full-width"
        onChange={(date) => onChange(fixLaDateParceQueMarcVeutPasLeFaire(date))}
        customInput={<ExampleCustomInput />}
        selected={selectedDate}
        dateFormat="yyyy-MM-dd HH:mm:ss"
        showTimeInput
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 175px;
`;

const Button = styled.button`
  width: 100%;
  height: 30px;
  font-size: 18px;
  padding: 5px;
  margin: 10px 0;
  background: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  border: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  ::placeholder {
    color: ${({ theme }) => theme.text};
  }
`;

DatePicker.propTypes = {
  label: string,
};

DatePicker.defaultProps = {
  label: "",
};

export default DatePicker;
