import React from "react";
import { Location } from "@gatsbyjs/reach-router";
import styled, { createGlobalStyle } from "styled-components";
import { getName } from "../helpers/utils";
import Section from "../components/Section";
import { Helmet } from "react-helmet";
import ApiResource from "../components/Section/ApiResource";

const PageTemplate = ({ path, pageContext, location }) => {
  const isCallback = location.pathname.split("/").includes("callback");

  function removeAllOf(obj) {
    const result = JSON.parse(JSON.stringify(obj));

    function mergeAllOff(target, ...sources) {
      for (const source of sources) {
        if (source && typeof source === "object") {
          for (const key in source) {
            if (source[key] && typeof source[key] === "object" && !Array.isArray(source[key])) {
              target[key] = mergeAllOff(target[key] || {}, source[key]);
            } else {
              target[key] = source[key];
            }
          }
        }
      }
      return target;
    }

    function processObject(item) {
      if (typeof item !== "object" || item === null) return item;

      if (item.allOf) {
        return mergeAllOff({}, ...item.allOf);
      }

      for (const key in item) {
        if (typeof item[key] === "object") {
          item[key] = processObject(item[key]);
        }
      }

      return item;
    }

    return processObject(result);
  }

  const updatedPageContext = removeAllOf(pageContext);

  return (
    <Wrapper isCallback={isCallback}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`smsmode© | ${getName(
            location.pathname.split("/")[1]
          ).toUpperCase()} REST API`}
        </title>
      </Helmet>
      <GlobalStyles />
      <Container isCallback={isCallback}>
        <ApiResource pageContext={updatedPageContext} />

        {updatedPageContext?.section &&
          Object.values(updatedPageContext?.section).map((section) => (
            <Section
              key={`section-${section.path}`}
              pageContext={updatedPageContext}
              section={section}
              id={section.path}
            />
          ))}
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
`;

const Wrapper = styled.div``;

const GlobalStyles = createGlobalStyle`
  .lock-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <PageTemplate {...locationProps} {...props} />}
  </Location>
);
