import React, { useMemo } from "react";
import styled from "styled-components";
import { Location } from "@gatsbyjs/reach-router";
import StatusTable from "../../components/PageElements/Elements/StatusTable";
import SecondStatusTable from "../../components/PageElements/Elements/SecondStatusTable";
import MsgCountTable from "../../components/PageElements/Elements/MsgCountTable";

import { Title, New } from "../PageElements";
import CmsComponents from "../CmsComponents";
import ResourceTable from "../ResourceTable";
import CopyCode from "./CopyCode";
import { useMeasure } from "../../hooks/useMeasure";
import {
  Wrapper as ResourceWrapper,
  Container,
  RightContainer
} from "./Endpoint";
import { Wrapper as RightPartWrapper, Tabs, Content } from "./RightPart";
import {
  Wrapper as ResponseWrapper,
  Container as ResponseContainer
} from "./Responses";

const ApiResource = ({ pageContext, location }) => {
  const { desc, title, isNew } = pageContext;
  const isCallback = location.pathname.split("/").includes("callback");
  const [ref, dimensions] = useMeasure();

  const resource = useMemo(() => {
    const parseObject = (schema, parent) => {
      const res = {};
      Object.entries(schema).forEach(([property, details]) => {
        if (
          pageContext.resources.some(
            (e) => e.replace(/[{}]/g, "") === `${parent}${property}`
          )
        ) {
          if (details.type === "object") {
            res[property] =
              details?.properties !== undefined
                ? parseObject(details.properties, `${parent}${property}.`)
                : JSON.parse(details?.example || '{}');
          } else if (details.type === "array") {
            res[property] =
              details?.items?.properties !== undefined
                ? [
                    parseObject(
                      details.items.properties,
                      `${parent}${property}.`
                    )
                  ]
                : JSON.parse(details?.example || '[]');
          } else {
            res[property] = details?.example ?? "N/A";
          }
        }
      });
      return res;
    };
    return pageContext?.schemas?.[1]?.properties
      ? parseObject(pageContext.schemas[1].properties, "")
      : {};
  }, [pageContext]);

  return (
    <Wrapper>
      <Title style={{ display: "flex" }}>
        <p>{title}</p>
        {isNew && <New>new</New>}
      </Title>
      <Summary dangerouslySetInnerHTML={{ __html: desc }} />
      <CmsComponents anchor="page_summary" />
      {!isCallback && (
        <ResourceWrapper>
          <Container>
            <ResourceTable
              className="resource-step"
              resources={pageContext.resources}
              schemas={pageContext.schemas}
            />
          </Container>
          <RightContainer>
            <OverrideRightPartWrapper
              ref={ref}
              offsetTop={
                document.getElementById("layout_content")?.offsetTop ?? 0
              }
            >
              <Tabs>
                <Content width={dimensions.width ?? -1}>
                  <ResponseWrapper>
                    <ResponseContainer>
                      <CopyCode label="JSON Resource">
                        {JSON.stringify(resource, null, 2)}
                      </CopyCode>
                    </ResponseContainer>
                  </ResponseWrapper>
                </Content>
              </Tabs>
            </OverrideRightPartWrapper>
          </RightContainer>
        </ResourceWrapper>
      )}
      {(pageContext.title === "Message" ||
        pageContext.title === "Campaign") && (
        <>
          <MsgCountTable />
          <StatusTable />
          {/* <SecondStatusTable /> */}
        </>
      )}
      {pageContext.title === "Message" && (
        <>
          <SecondStatusTable />
        </>
      )}
      {pageContext.title === "Consumption" && (
        <>
          <StatusTable />
        </>
      )}
    </Wrapper>
  );
};

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <ApiResource {...locationProps} {...props} />}
  </Location>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Summary = styled.p`
  width: 100%;
  max-width: 800px;
  line-height: 25px;
  font-size: 14px;
  & > div > div {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #30cd6d;
    margin: 10px;
  }
  & > div {
    display: flex;
    align-items: center;
  }
  & > div > span,
  & > span {
    font-weight: 900;
  }
  & > a {
    color: #52b474;
  }
  @media (max-width: 550px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
`;

const OverrideRightPartWrapper = styled(RightPartWrapper)`
  height: unset;
  max-height: calc(100vh - ${({ offsetTop }) => `${offsetTop}px`} - 40px);
`;
