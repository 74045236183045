const generatePythonSnippet = ({ apiName, endpoint, data, operation }) => {
  const url = new URL(`${apiName}/${endpoint}`, process.env.GATSBY_API_ROOT);

  Object.entries(data.query).forEach(([key, value]) =>
    url.searchParams.append(key, value)
  );

  const headers = Object.entries(data.header)
    .filter(([key, value]) => !!value || key === 'X-Api-Key')
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const formattedHeaders = JSON.stringify(headers, null, 4)
    .split('\n')
    .map((line, index) => index === 0 ? line : `    ${line}`)
    .join('\n');

  const formattedPayload = Object.keys(data.body).length 
    ? `\npayload = ${JSON.stringify(data.body, null, 4)
        .split('\n')
        .map((line, index) => index === 0 ? line : `    ${line}`)
        .join('\n')}`
    : '';

  return `import requests

url = "${url}"
headers = ${formattedHeaders}${formattedPayload}

try:
    response = requests.${operation.toLowerCase()}(
        url,
        headers=headers${Object.keys(data.body).length ? ',\n        json=payload' : ''}
    )
    response.raise_for_status()
    print(response.json())
except requests.exceptions.RequestException as e:
    print(f"Error: {e}")`;
};

export default generatePythonSnippet;