import React, { useContext, useReducer, useState } from "react";
import moment from "moment";

import styled from "styled-components";
import { object, string } from "prop-types";
import _ from "lodash";

import Table from "./Table";
import RightPart from "./RightPart";
import Request from "./Request";
import Snippets from "./Snippets";
import Responses from "./Responses";

import { Location } from "@gatsbyjs/reach-router";
import { getName } from "../../helpers/utils";
import CmsComponents from "../CmsComponents";
import { X_API_KEY_KEY } from "../Auth";
import { Context } from "../Layout";

const reducer = (state, action) => {
  const data = _.get(state, action.payload.name);
  switch (action.type) {
    case "body":
    case "path":
    case "query":
    case "header":
      const newState = { ...state };
      if (action.payload.name.includes("sortBy")) {
        const parentName = action.payload.name.replace(/(\.|\[")?sortBy.*/, "");
        const parent = _.get(
          state,
          `${action.type}${parentName ? "." : ""}${parentName}`
        );
        const otherSorts = Object.keys(parent).filter(
          (e) =>
            e.startsWith("sortBy") && !action.payload.name.includes(e)
        );
        const newParent = otherSorts.reduce(
          (acc, curr) => _.omit({ ...acc }, curr),
          { ...parent }
        );
        _.set(
          newState,
          `${action.type}${parentName ? "." : ""}${parentName}`,
          newParent
        );
      }
      return _.set(
        newState,
        `${action.type}.${action.payload.name}`,
        action.payload.value
      );
    case "set":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "addArrayElem":
      if (!Array.isArray(data)) {
        return state;
      }
      return _.set({ ...state }, action.payload.name, [
        ...data,
        action.payload.value,
      ]);
    case "removeArrayElem":
      if (!Array.isArray(data)) {
        return state;
      }
      return _.set({ ...state }, action.payload.name, [
        ...data.slice(0, action.payload.value),
        ...data.slice(action.payload.value + 1),
      ]);
    default:
      return state;
  }
};

export const initState = (data) => {
  return {
    body: parseDescription(data),
    ...parseParams(data),
  };
};

export const parseDescription = (data) => {
  const { description } = data;
  if (!description) return {};
  const params = description
    .slice(/^\[[^\]]+\]/.test(description) ? description.indexOf("]") + 2 : 0)
    .split("|")
    .filter(Boolean);
  const valueMap = {
    object: {},
    array: [],
  };

  const parseObject = (param, schema) => {
    const cleanParam = param.replace(/[{}*]/g, "");
    const levels = cleanParam.split(".");

    const parseChain = (chain, schema) => {
      const isArray = chain[0].endsWith("[]");
      const key = chain[0].replace("[]", "");
      if (chain.length === 1) {
        return {
          [key]: isArray
            ? []
            : (valueMap[schema[key].type] ?? ""),
        };
      }
      const data = parseChain(
        chain.slice(1),
        isArray ? schema[key].items.properties : schema[key].properties
      );
      return { [key]: isArray ? [data] : data };
    };
    return parseChain(levels, schema);
  };

  let res = {};
  for (const param of params) {
      res = _.merge(
        res,
        parseObject(
          param,
          data.requestBody.content["application/json"].schema.properties
        )
      );
  }
  return res;
};

export const parseParams = (data) => {
  const { parameters } = data;
  const res = { path: {}, query: {}, header: {} };
  const valueMap = {
    string: "",
    boolean: false,
    integer: 0,
  };

  if (parameters) {
    for (const param of parameters) {
      if (param.name.startsWith("sortBy[")) {
        const fields = param.name
          .replace("sortBy[", "")
          .replace("]")
          .split("|");
        res[param.in][`sortBy[${fields[0]}]`] =
          valueMap[param.schema.type] ?? "";
      } else if (param.name.toLocaleLowerCase() !== "x-api-key") {
        const val =
          param.schema.default ??
          param.schema.minimum ??
          valueMap[param.schema.type];
        res[param.in][param.name] = val;
      }
    }
  }
  return res;
};

export const stateToRequest = (state, method, apiKey) => {
  const removeEmptyStrings = (obj) => {
    return _.transform(obj, (result, value, key) => {
      if (_.isObject(value) && !_.isDate(value)) {
        value = removeEmptyStrings(value);
      }
      if (!_.isEmpty(value) || _.isNumber(value) || _.isBoolean(value)) {
        result[key] = value;
      } else if (_.isDate(value)) {
        result[key] = moment(value).format("YYYY-MM-DDThh:mm:ssZ");
      }
    });
  };

  const body = Object.keys(state.body).some((e) => e.startsWith("body-"))
    ? Object.entries(state.body).reduce(
        (acc, curr) => [...acc, removeEmptyStrings(curr[1])],
        []
      )
    : removeEmptyStrings(state.body);

  return {
    body,
    header: {
      ...removeEmptyStrings(state.header),
      Accept: "application/json",
      "Content-Type": ["post", "patch"].includes(method)
        ? "application/json"
        : undefined,
      [X_API_KEY_KEY]: apiKey || "",
    },
    path: removeEmptyStrings(state.path),
    query: removeEmptyStrings(state.query),
  };
};

const EndPoint = ({ section, op, location, ...props }) => {
  const [state, dispatch] = useReducer(
    reducer,
    section.additionalData[op],
    initState
  );
  const isCallback = location.pathname.split("/").includes("callback");
  const [response, setResponse] = useState(null);

  const [currentPath, setCurrentPath] = useState("");
  const [activeTab, setActiveTab] = useState("request");
  const { width } = useContext(Context);
  const [showRightPart, setShowRightPart] = useState(false);
  const [selectedExample, setSelectedExample] = useState(null);

  const [loading, setLoading] = useState(false);


  return (
    <Wrapper isCallback={isCallback}>
      {(width >= 1000 || !showRightPart) && (
        <Container
          isCallback={isCallback}
          id={`${op}_${section.additionalData[op].summary
            ?.replace(" ", "_")
            .toLocaleLowerCase()}`}
        >
          <Summary>
            {section?.additionalData?.[op]?.summary || "No summary"}
          </Summary>
          <CmsComponents
            currentPath={currentPath}
            setCurrentPath={setCurrentPath}
            apiName={getName(location.pathname.split("/")[1]).toUpperCase()}
            anchor={`top_table_${op}_${section.additionalData[op].summary
              ?.replace(" ", "_")
              .toLocaleLowerCase()}`}
          />
          {width < 1000 && (
            <ExamplesButton onClick={() => setShowRightPart(true)}>
              Show examples
            </ExamplesButton>
          )}

          <Table
            {...props}
            key={`${section.path}-${op}-table`}
            state={state}
            dispatch={dispatch}
            type={op}
            path={section.path}
            operation={section.additionalData[op]}
            id={`${section.additionalData[op].summary
              ?.replace(" ", "_")
              .toLocaleLowerCase()}`}
            setResponse={setResponse}
            setCurrentPath={setCurrentPath}
            currentPath={currentPath}
            setActiveTab={setActiveTab}
            setShowRightPart={setShowRightPart}
            loading={loading}
            setLoading={setLoading}
          />
          <CmsComponents
            currentPath={currentPath}
            setCurrentPath={setCurrentPath}
            apiName={getName(location.pathname.split("/")[1]).toUpperCase()}
            anchor={`bottom_table_${op}_${section.additionalData[op].summary
              ?.replace(" ", "_")
              .toLocaleLowerCase()}`}
            noMargin
          />
        </Container>
      )}
      {(width >= 1000 || showRightPart) && (
        <RightContainer>
          {width < 1000 && (
            <>
              <Summary>
                {section?.additionalData?.[op]?.summary || "No summary"}
              </Summary>
              <ExamplesButton onClick={() => setShowRightPart(false)}>
                Hide examples
              </ExamplesButton>
            </>
          )}
          <RightPart
            activeTab={activeTab}
            onTabChange={(e) => setActiveTab(e)}
            tabs={{
              request: {
                label: "Live request",
                content: (
                  <Request
                    {...props}
                    state={state}
                    dispatch={dispatch}
                    exampleData={section}
                    operation={op}
                    currentPath={currentPath}
                    response={response}
                    selectedExample={selectedExample}
                    setSelectedExample={setSelectedExample}
                    loading={loading}
                  />
                ),
              },
              snippets: {
                label: "Snippets",
                content: (
                  <Snippets
                    {...props}
                    state={state}
                    dispatch={dispatch}
                    exampleData={section}
                    operation={op}
                    currentPath={currentPath}
                    response={response}
                    selectedExample={selectedExample}
                    setSelectedExample={setSelectedExample}
                  />
                ),
              },
              responses: {
                label: "Example of responses",
                content: (
                  <Responses
                    responses={section?.additionalData[op]?.responses}
                  />
                ),
              },
            }}
          />
        </RightContainer>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const RightContainer = styled.div`
  position: relative;
  min-width: 0;
  // display: flex;
  // margin: 0 30px;
  // width: 100%;
  // max-width: 755px;
  // & > div {
  //   width: 100%;
  //   max-width: 755px;
  //   position: sticky;
  //   top: 70px;
  //   height: fit-content;
  // }
`;

export const Container = styled.div`
  min-width: 0;
  position: relative;
  // max-width: 850px;
  // width: 100%;
  // display: ${({ isCallback }) => isCallback && "flex"};
  // flex-direction: ${({ isCallback }) => isCallback && "column"};
  // padding-top: ${({ isCallback }) => isCallback && "80px"};
  // @media (max-width: 475px) {
  //   padding-top: ${({ isCallback }) => isCallback && "475px"};
  // }
`;

const Summary = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
`;

const ExamplesButton = styled.button`
  outline: none;
  margin-bottom: 20px;
  cursor: pointer;
  background: transparent;
  border: ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.text};
  padding: 10px;
  border-radius: 5px;
`

EndPoint.propTypes = {
  section: object,
  id: string,
};

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <EndPoint {...locationProps} {...props} />}
  </Location>
);
