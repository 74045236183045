import React, { useState } from 'react'
import styled from 'styled-components'
import Add from "../../../svg/add";
import Trash from "../../../svg/trash";

import Input from '../Input'

const ObjectInput = ({ className, value = {}, onChange }) => {
  const [state, setState] = useState({ key: '', value: '' })

  const addEntry = () => {
    if (!state.key || !state.value) {
      return
    }

    const newValue = Object.fromEntries(Object.entries(value))
    if (newValue.hasOwnProperty(state.key)) {
      return
    }

    onChange({ ...newValue, [state.key]: state.value })
    setState({ key: '', value: '' })
  }

  const removeEntry = (key) => {
    const newValue = Object.fromEntries(Object.entries(value))
    if (newValue.hasOwnProperty(key)) {
      delete newValue[key]
      onChange(newValue)
    }
  }

  return (
    <Column className={className}>
      {Object.entries(value).map((entry) => (
        <Row key={`${entry[0]}-${entry[1]}`}>
          <ActionButton onClick={() => removeEntry(entry[0])}><Trash/></ActionButton>
          <p>{`"${entry[0]}": "${entry[1]}"`}</p>
        </Row>
      ))}
      <Row>
        <Column>
          <Input placeholder={"key :"} value={state.key} onChange={({ target: { value } }) => setState((s) => ({ ...s, key: value }))} />
        </Column>
        <Column>
          <Input placeholder={"value :"} value={state.value} onChange={({ target: { value } }) => setState((s) => ({ ...s, value }))} />
        </Column>
        <ActionButton onClick={addEntry} type="button">
          <Add/>
        </ActionButton>
      </Row>
    </Column>
  )
}

export default ObjectInput

const Column = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`

const ActionButton = styled.button`
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.body};
  border: none;
`;


const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`