const generateCsharpSnippet = ({ apiName, endpoint, data, operation }) => {
  const url = new URL(`${apiName}/${endpoint}`, process.env.GATSBY_API_ROOT);

  Object.entries(data.query || {}).forEach(([key, value]) =>
    url.searchParams.append(key, value)
  );

  const headers = Object.entries(data.header || {})
    .filter(([key, value]) => !!value || key === 'X-Api-Key')
    .map(([key, value]) =>
      key === "Authorization" && value.startsWith("Basic ")
        ? `        string base64String = Convert.ToBase64String(Encoding.ASCII.GetBytes("${value.replace("Basic ", "")}"));
        client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue("Basic", base64String);`
        : `        client.DefaultRequestHeaders.Add("${key}", "${value}");`
    )
    .join('\n');

  const generateCsharpObject = (obj, indent = 12) => {
    const indentation = ' '.repeat(indent);
    if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
      return `new\n${indentation}{\n${Object.entries(obj)
        .map(
          ([key, value]) =>
            `${indentation}    ${key} = ${generateCsharpObject(value, indent + 4)}`
        )
        .join(',\n')}\n${indentation}}`;
    } else if (Array.isArray(obj)) {
      return `new[]\n${indentation}{\n${obj
        .map((item) => `${indentation}    ${generateCsharpObject(item, indent + 4)}`)
        .join(',\n')}\n${indentation}}`;
    } else if (typeof obj === 'string') {
      return `"${obj.replace(/"/g, '\\"')}"`;
    } else if (typeof obj === 'boolean') {
      return obj ? 'true' : 'false';
    } else if (obj === null || obj === undefined) {
      return 'null';
    } else {
      return obj;
    }
  };

  const csharpBody = generateCsharpObject(data.body || {}, 12);

  return `using System;
using System.Net.Http;
using System.Text;
using System.Threading.Tasks;
using System.Text.Json;
using System.Net.Http.Headers;

class Program
{
    static async Task Main()
    {
        using var client = new HttpClient();

${headers}

        var json = JsonSerializer.Serialize(${csharpBody});

        try
        {
            using var content = new StringContent(json, Encoding.UTF8, "application/json");
            var response = await client.PostAsync("${url}", content);
            response.EnsureSuccessStatusCode();
            var responseBody = await response.Content.ReadAsStringAsync();
            Console.WriteLine(responseBody);
        }
        catch (HttpRequestException e)
        {
            Console.WriteLine($"Error: {e.Message}");
        }
    }
}`;
};

export default generateCsharpSnippet;
