import React from "react";
import { createContext, useContext, useState } from "react";

const defaultContext = {
  fields: [],
  setField: () => {},
  removeField: () => {},
};

export const FormContext = createContext(defaultContext);

const Form = ({ onSubmit, ...props }) => {
  const { fields } = useContext(FormContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (fields.every((field) => field.validate())) {
      onSubmit?.(e);
    } else {
      fields.forEach((field) => {
        field.setDisplayErrorMessage(true);
      });
      console.error("Certains champs ne sont pas valides.");
    }
  };

  return <form {...props} onSubmit={handleSubmit} />;
};

const FormProvider = (props) => {
  const [fields, setFields] = useState(defaultContext.fields);

  return (
    <FormContext.Provider
      value={{
        fields,
        setField: (fieldId, data) => {
          if (!fields.some((field) => field.id === fieldId)) {
            setFields((currentFields) => [
              ...currentFields,
              {
                id: fieldId,
                isValid: false,
                setDisplayErrorMessage: () => {},
                ...data,
              },
            ]);
            return;
          }
          setFields((currentFields) =>
            currentFields.reduce(
              (acc, current) => [
                ...acc,
                current.id === fieldId ? { ...current, ...data } : current,
              ],
              []
            )
          );
        },
        removeField: (fieldId) => {
          setFields((currentFields) =>
            currentFields.filter((field) => field.id !== fieldId)
          );
        },
      }}
    >
      <Form {...props} />
    </FormContext.Provider>
  );
};

export default FormProvider;
