import React, { useEffect, useState } from "react";
import { Location } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import { Table } from "antd";

import { getName } from "../../helpers/utils";

import "antd/lib/table/style/index.css";

import "../../themes/css/index.css";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (html) => {
      return <Colored>{html}</Colored>;
    }
  },
  { title: "Type", dataIndex: "type", key: "type" },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (html) => {
      return <Container dangerouslySetInnerHTML={{ __html: html }} />;
    }
  }
];

const ResourceTable = ({ resources, schemas, location }) => {
  const [subData, setSubData] = useState();

  const getSubRawData = () => {
    let tmp = [];

    resources &&
      resources.map((item) => item.charAt(0) === "{" && tmp.push(item));
    setSubData(tmp);
  };

  useEffect(() => {
    getSubRawData();
    // eslint-disable-next-line
  }, []);

  const filterResources = (res, itemName) => {
    if (!res) return;
    let filter = subData
      .filter((item) => item.includes(itemName))
      .map(
        (child) =>
          child.replace("{", "").replace("}", "").split(".")[
            child.split(".").length - 1
          ]
      );
    const sort = Object.entries(res.properties ? res.properties : res).filter(
      (item) => filter.includes(item[0])
    );
    return Object.fromEntries(sort);
  };

  const expandedRowRender = (item) => {
    const data = [];

    Object.entries(
      item?.exp?.properties ? item.exp.properties : item?.exp
    ).forEach((raw, i) =>
      data.push({
        key: i,
        name: raw[0],
        type: raw[1]?.type,
        description: raw[1]?.description || "",
        ...((raw[1]?.properties || raw[1]?.items || raw[1]?.oneOf) && {
          exp: filterResources(
            raw[1]?.properties ||
              raw[1]?.items ||
              raw[1]?.oneOf.find(
                (e) =>
                  e.description.split(" ")[0].toLocaleLowerCase() ===
                  getName(location.pathname.split("/")[1])
              ).properties,
            raw[0]
          )
        })
      })
    );

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        expandable={{
          expandedRowRender: expandedRowRender,
          rowExpandable: (subItem) => {
            if (subItem.exp) return true;
            return false;
          }
        }}
      />
    );
  };

  const formatData = () => {
    let data = [];

    schemas &&
      Object.entries(schemas[1].properties).map(
        (item, i) =>
          resources &&
          resources?.includes(item[0]) &&
          data.push({
            key: i,
            name: item[0],
            type: item[1]?.type,
            description:
              item[1]?.description || item[1]?.items?.description || "",
            ...(item[1]?.properties && {
              exp: filterResources(item[1]?.properties, item[0])
            }),
            ...(item[1]?.items && {
              exp: filterResources(item[1]?.items.properties, item[0])
            }),
            ...(item[1]?.oneOf && {
              exp: filterResources(
                item[1]?.oneOf.find(
                  (e) =>
                    e.description.split(" ")[0].toLocaleLowerCase() ===
                    getName(location.pathname.split("/")[1])
                ).properties,
                item[0]
              )
            })
          })
      );
    return data;
  };

  return (
    <Wrapper data-testid="ResourceTable-Wrapper">
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: expandedRowRender,
          rowExpandable: (item) => {
            if (item.exp) return true;
            return false;
          }
        }}
        dataSource={subData && formatData()}
        pagination={false}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 10px 0px 20px;
  max-width: 850px;
  width: 100%;
  // border-bottom: 1px solid #4444445d;

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: ${({ theme }) => theme.border} !important;
  }

  .ant-table-cell,
  .ant-table {
    color: ${({ theme }) => theme.text} !important;
  }

  .ant-table {
    color: ${({ theme }) => theme.text} !important;
  }

  @media (max-width: 500px) {
    .ant-table-cell {
      width: 50px !important;
    }    
  }

`;

const Container = styled.div`
  & > span > a,
  & > a {
    color: rgb(82, 180, 116);
  }

  & > span {
    font-weight: bold;
  }
`;

const Colored = styled.div`
  width: fit-content;
  background-color: ${({ theme }) => theme.second};
  padding: 3px 5px;
  border-radius: 3px;
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <ResourceTable {...locationProps} {...props} />}
  </Location>
);
