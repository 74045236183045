import React, { useMemo, useContext } from "react";

import styled, { css } from "styled-components";
import { Location } from "@gatsbyjs/reach-router";
import { stateToRequest } from "../Endpoint";
import { Resizable } from "re-resizable";
import { useStaticQuery, graphql } from "gatsby";
import Spinner from "../../Spinner";
import { Context } from "../../Layout";

import { getName } from "../../../helpers/utils";
import CopyCode from "../CopyCode";
import Placeholder from "./Placeholder";
import NoContent from "./NoContent";
import ExamplesSelect from "../ExamplesSelect";

const Request = ({
  state,
  dispatch,
  location,
  exampleData,
  operation,
  currentPath,
  response,
  selectedExample,
  setSelectedExample,
  loading,
  ...props
}) => {
  const isCallback = location.pathname.split("/").includes("callback");
  const dataApi = useStaticQuery(graphql`
    query {
      allApi {
        nodes {
          version
          name
          servers {
            url
          }
        }
      }
    }
  `);
  const { apiKey } = useContext(Context);
  const data = useMemo(
    () => stateToRequest(state, operation, apiKey),
    [state, operation, apiKey]
  );

  const apiData = useMemo(
    () =>
      dataApi.allApi.nodes.find(
        (node) => getName(node.name) === location.pathname.split("/")[1]
      ),
    [dataApi, location]
  );

  const apiName = useMemo(
    () => `${getName(apiData.name)}/${apiData.version.split(".")[0]}`,
    [apiData]
  );

  const url = useMemo(() => {
    const endpoint = currentPath
      .split("/")
      .map((e) =>
        e.startsWith("{") ? data.path[e.slice(1, e.length - 1)] || e : e
      )
      .join("/");
    const url = new URL(`${apiName}/${endpoint}`, process.env.GATSBY_API_ROOT);
    Object.entries(data.query).forEach(([key, value]) =>
      url.searchParams.append(key, value)
    );
    return url.toString().replace(/%7B/g, "{").replace(/%7D/g, "}");
  }, [currentPath, data, apiName]);

  const getDotColor = (code) => {
    if (code.startsWith("2")) return "green";
    if (code.startsWith("4")) return "orange";
    if (code.startsWith("5")) return "red";
    return "grey";
  };

  return (
    <Wrapper>
      <RequestContainer
        enable={{ bottom: !isCallback }}
        handleComponent={{ bottom: <ResizeHandle /> }}
        $isCallback={isCallback}
        id={`${operation}-${exampleData.path}`}
      >
        {!["get", "delete"].includes(operation) && (
          <ExamplesSelect
            operation={operation}
            exampleData={exampleData}
            value={selectedExample}
            onChange={setSelectedExample}
            dispatch={dispatch}
          />
        )}

        {!isCallback && (
          <>
            <CopyCode label="URL" fit expendable expendedByDefault>
              {url}
            </CopyCode>
            <CopyCode label="Headers" fit expendable expendedByDefault>
              {Object.entries(data.header)
                .filter(([key, value]) => key === "X-Api-Key" || !!value)
                .reduce((acc, [key, value]) => `${acc}${key}: ${value}\n`, "")}
            </CopyCode>
          </>
        )}
        {["post", "patch"].includes(operation) && (
          <CopyCode label="Body">{JSON.stringify(data.body, null, 2)}</CopyCode>
        )}
      </RequestContainer>
      {!isCallback && (
        <ResponseContainer>
          {!!response && (
            <ResponseTitle>
              Response
              <Dot color={getDotColor(response?.status.toString())} />
              {response?.status.toString()}
            </ResponseTitle>
          )}

          {loading ? (
            <ResponseCallToAction>
              <Spinner />
            </ResponseCallToAction>
          ) : (
            <>
              {!!response?.data && response?.status !== 204 && (
                <CopyCode>{JSON.stringify(response.data, null, 2)}</CopyCode>
              )}
              {response?.status === 204 && (
                <ResponseCallToAction>
                  <NoContent />
                  <p>No content!</p>
                </ResponseCallToAction>
              )}
              {!response?.data && response?.status !== 204 && (
                <ResponseCallToAction>
                  <StyledPlaceholder />
                  <p>
                    Click on <span>Send</span> to get a response!
                  </p>
                </ResponseCallToAction>
              )}
            </>
          )}
        </ResponseContainer>
      )}
    </Wrapper>
  );
};

const ResponseTitle = styled.p`
  color: #fff;
  padding: 2px 8px;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const ResponseCallToAction = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  & > p > span {
    color: #52b474;
  }
`;

const StyledPlaceholder = styled(Placeholder)`
  width: 70%;
  height: 70%;
  max-height: 150px;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const container = css`
  padding: 10px;
  background-color: rgb(36, 63, 91, 0.9);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const RequestContainer = styled(Resizable)`
  ${container}
  max-height: ${({ $isCallback }) =>
    $isCallback ? "100%" : "calc(100% - 150px - 10px)"};
  gap: 10px;
  flex-basis: 100%;
`;

const ResponseContainer = styled.div`
  ${container}
  flex: 1 1 auto;
  min-height: 150px;
`;

const ResizeHandle = styled.div`
  width: 20%;
  height: 10px;
  border-radius: 5px;
  background-color: #52b474;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin: 0 8px;
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <Request {...locationProps} {...props} />}
  </Location>
);
