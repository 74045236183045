import React, { useState, useMemo, useContext } from "react";
import styled from "styled-components";
import { Location } from "@gatsbyjs/reach-router";
import { useStaticQuery, graphql } from "gatsby";

import { stateToRequest } from "../Endpoint";
import { Context } from "../../Layout";
import { getName } from "../../../helpers/utils";
import CopyCode from "../CopyCode";
import { Wrapper, Container } from "../Responses";
import ExamplesSelect from "../ExamplesSelect";

import generateCurlSnippet from "./curl";
import generateNodejsSnippet from "./nodejs";
import generatePhpSnippet from "./php";
import generateJavaSnippet from "./java";
import generatePython from "./python";
import generateCsharp from "./csharp";

const Snippets = ({
  state,
  dispatch,
  location,
  exampleData,
  operation,
  currentPath,
  response,
  selectedExample,
  setSelectedExample,
  ...props
}) => {
  const isCallback = location.pathname.split("/").includes("callback");
  const dataApi = useStaticQuery(graphql`
    query {
      allApi {
        nodes {
          version
          name
          servers {
            url
          }
        }
      }
    }
  `);
  const { apiKey } = useContext(Context);
  const data = useMemo(
    () => stateToRequest(state, operation, apiKey),
    [state, operation, apiKey]
  );

  const apiData = useMemo(
    () =>
      dataApi.allApi.nodes.find(
        (node) => getName(node.name) === location.pathname.split("/")[1]
      ),
    [dataApi, location]
  );

  const apiName = useMemo(
    () => `${getName(apiData.name)}/${apiData.version.split(".")[0]}`,
    [apiData]
  );

  const endpoint = useMemo(() => {
    return currentPath
      .split("/")
      .map((e) =>
        e.startsWith("{")
          ? data.path[e.slice(1, e.length - 1)] || e.replace(/[{}]/g, "")
          : e
      )
      .join("/");
  }, [currentPath, data]);

  const [selectedLanguage, setSelectedLanguage] = useState("curl");

  const generateSnippet = useMemo(() => {
    switch (selectedLanguage) {
      case "curl":
        return generateCurlSnippet;
      case "node":
        return generateNodejsSnippet;
      case "php":
        return generatePhpSnippet;
      case "java":
        return generateJavaSnippet;
      case "python":
        return generatePython;
      case "csharp":
        return generateCsharp;
      default:
        return () => "";
    }
  }, [selectedLanguage]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <Wrapper>
      <Container>
        {!["get", "delete"].includes(operation) && (
          <ExamplesSelect
            operation={operation}
            exampleData={exampleData}
            value={selectedExample}
            onChange={setSelectedExample}
            dispatch={dispatch}
          />
        )}
        {!isCallback && (
          <LanguageButtons>
            <LanguageButton
              isActive={selectedLanguage === "curl"}
              onClick={() => handleLanguageChange("curl")}
            >
              cURL
            </LanguageButton>
            <LanguageButton
              isActive={selectedLanguage === "node"}
              onClick={() => handleLanguageChange("node")}
            >
              Node.js
            </LanguageButton>
            <LanguageButton
              isActive={selectedLanguage === "php"}
              onClick={() => handleLanguageChange("php")}
            >
              PHP
            </LanguageButton>
            <LanguageButton
              isActive={selectedLanguage === "java"}
              onClick={() => handleLanguageChange("java")}
            >
              Java
            </LanguageButton>
            <LanguageButton
              isActive={selectedLanguage === "csharp"}
              onClick={() => handleLanguageChange("csharp")}
            >
              C#
            </LanguageButton>
            <LanguageButton
              isActive={selectedLanguage === "python"}
              onClick={() => handleLanguageChange("python")}
            >
              Python
            </LanguageButton>
          </LanguageButtons>
        )}

        <CopyCode>
          {generateSnippet({ apiName, endpoint, data, operation })}
        </CopyCode>
      </Container>
    </Wrapper>
  );
};

const LanguageButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px 0;
`;

const LanguageButton = styled.button`
  background-color: ${({ isActive }) =>
    isActive ? "#52b474" : "rgb(20, 45, 71)"};
  color: ${({ isActive }) => (isActive ? "#fff" : "#bbb")};
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  width: 70px;
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <Snippets {...locationProps} {...props} />}
  </Location>
);
