import React, { useEffect, useRef, useState, useContext } from "react";
// import parseHtml from "html-react-parser";
import { v4 } from "uuid";

import mergeRefs from "../helpers/mergeRefs";
import { FormContext } from "../components/Form";

export const errorMessageContainerId = "ValidatorsWrapper-errorMessage";

const withValidators =
  (WrappedComponent) =>
  ({ onChange, validators, ref, ...props }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const wrappedComponentRef = useRef(null);
    const [fieldId] = useState(v4());
    const { setField, removeField } = useContext(FormContext);

    const validate = (target, event) => {
      if (!validators?.length) return;

      const errorMessages = [];
      let displayErrMessage = false;

      validators.forEach((validator) => {
        if (!validator.validator) return;

        const options = validator.options;
        const error = validator.validator(target[options.fieldToCheck] || "");

        if (error && !errorMessages.includes(error)) {
          errorMessages.push(error);
        }

        if (error && event === "mount" && options.checkOnMount) {
          displayErrMessage = true;
        }
        if (error && event === "change" && options.checkOnChange) {
          displayErrMessage = true;
        }
        if (error && event === "validate") {
          displayErrMessage = true;
        }
      });

      setErrorMessage(errorMessages.join("<br />"));
      setDisplayErrorMessage(displayErrMessage);
      setField(fieldId, {
        isValid: !errorMessages.length,
        setDisplayErrorMessage,
        validate: () => validate(target, "validate"),
      });
      return !errorMessages.length
    };

    useEffect(() => {
      if (!wrappedComponentRef?.current) return;
      validate(wrappedComponentRef.current, "mount");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrappedComponentRef]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => removeField(fieldId), []);

    return (
      <>
        <WrappedComponent
          {...props}
          ref={mergeRefs(ref, wrappedComponentRef)}
          onChange={(e) => {
            validate(e.target, "change");
            onChange?.(e);
          }}
          hasErrors={!!errorMessage && displayErrorMessage}
        />
        {/* {!!errorMessage && displayErrorMessage && (
          <ErrorContainer>
            {parseHtml(errorMessage)}
          </ErrorContainer>
        )} */}
      </>
    );
  };

export default withValidators;

// const ErrorContainer = styled.div`
//   font-size: 14px;
//   color: ${(props) => props.theme.error};
//   margin-bottom: 15px;
//   text-align: end;
// `;
