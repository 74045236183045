const generatePhpSnippet = ({ apiName, endpoint, data, operation }) => {
  const url = new URL(`${apiName}/${endpoint}`, process.env.GATSBY_API_ROOT);
  
  Object.entries(data.query).forEach(([key, value]) =>
    url.searchParams.append(key, value)
  );

  const headers = Object.entries(data.header)
    .filter(([key, value]) => !!value || key === 'X-Api-Key')
    .map(([key, value]) => `        "${key}: ${value}"`);

  const bodyContent = Object.keys(data.body).length 
    ? `,\n    CURLOPT_POSTFIELDS => '${JSON.stringify(data.body, null, 2)
        .split('\n')
        .map((line, index) => index === 0 ? line : '    ' + line)
        .join('\n')}'`
    : '';

  return `<?php

$curl = curl_init();

curl_setopt_array($curl, [
    CURLOPT_URL => "${url}",
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_CUSTOMREQUEST => "${operation.toUpperCase()}",
    CURLOPT_HTTPHEADER => [
${headers.join(",\n")}
    ]${bodyContent}
]);

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
    echo "Error: " . $err;
} else {
    echo $response;
}`;
};

export default generatePhpSnippet;