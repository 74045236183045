import React from "react";

const NoContent = (props) => {
  return (
    <svg
      width="127"
      height="130"
      viewBox="0 0 127 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M79.436 94.716C105.591 94.716 126.794 73.5131 126.794 47.358C126.794 21.2029 105.591 0 79.436 0C53.2809 0 32.078 21.2029 32.078 47.358C32.078 73.5131 53.2809 94.716 79.436 94.716Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.05"
        d="M79.437 86.454C101.029 86.454 118.533 68.9501 118.533 47.358C118.533 25.7658 101.029 8.26196 79.437 8.26196C57.8449 8.26196 40.341 25.7658 40.341 47.358C40.341 68.9501 57.8449 86.454 79.437 86.454Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M79.436 79.3999C97.1323 79.3999 111.478 65.0542 111.478 47.3579C111.478 29.6616 97.1323 15.3159 79.436 15.3159C61.7397 15.3159 47.394 29.6616 47.394 47.3579C47.394 65.0542 61.7397 79.3999 79.436 79.3999Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M79.436 70.332C92.1242 70.332 102.41 60.0462 102.41 47.358C102.41 34.6698 92.1242 24.384 79.436 24.384C66.7478 24.384 56.462 34.6698 56.462 47.358C56.462 60.0462 66.7478 70.332 79.436 70.332Z"
        fill="black"
      />
      <path
        d="M3.19703 51.5959C3.19703 51.5959 -0.525972 62.0759 1.12803 65.7959C2.32629 68.358 3.7573 70.8046 5.40303 73.1049C5.40303 73.1049 4.43803 52.2859 3.19703 51.5959Z"
        fill="#D0CDE1"
      />
      <path
        opacity="0.1"
        d="M3.19703 51.5959C3.19703 51.5959 -0.525972 62.0759 1.12803 65.7959C2.32629 68.358 3.7573 70.8046 5.40303 73.1049C5.40303 73.1049 4.43803 52.2859 3.19703 51.5959Z"
        fill="black"
      />
      <path
        d="M5.81702 75.8671C5.73749 76.7942 5.59921 77.7154 5.40302 78.6251C5.26502 78.7631 5.54102 79.0391 5.40302 79.4521C5.26502 79.8651 5.12702 80.4171 5.40302 80.5521C5.67902 80.6871 3.88602 92.8251 3.88602 92.8251C3.88602 92.8251 -0.526978 98.6171 1.26602 107.718L1.81802 116.957C1.81802 116.957 6.09303 117.233 6.09303 115.716C5.95504 114.848 5.86291 113.974 5.81702 113.096C5.81702 112.269 6.50603 112.269 6.09303 111.855C5.68003 111.441 5.67902 111.166 5.67902 111.166C5.67902 111.166 6.36802 110.614 6.23102 110.476C6.09402 110.338 7.47202 100.547 7.47202 100.547C7.47202 100.547 8.98902 99.0301 8.98902 98.2031V97.3761C8.98902 97.3761 9.67802 95.5831 9.67802 95.4451C9.67802 95.3071 13.401 86.8951 13.401 86.8951L14.918 92.9631L16.573 101.651C16.573 101.651 17.4 109.511 19.055 112.545C19.055 112.545 21.955 122.474 21.955 122.198C21.955 121.922 26.781 121.233 26.644 119.992C26.507 118.751 23.744 101.375 23.744 101.375L24.436 75.5911L5.81702 75.8671Z"
        fill="#2F2E41"
      />
      <path
        d="M2.09401 116.273C2.09401 116.273 -1.629 123.582 0.853004 123.858C3.335 124.134 4.30101 124.134 5.40401 123.031C6.27441 122.27 7.21457 121.593 8.212 121.009C8.79531 120.654 9.26653 120.141 9.57149 119.53C9.87646 118.919 10.0028 118.234 9.936 117.554C9.863 116.879 9.611 116.323 8.991 116.272C7.68266 116.009 6.45453 115.442 5.406 114.617L2.09401 116.273Z"
        fill="#2F2E41"
      />
      <path
        d="M22.641 121.649C22.641 121.649 18.918 128.958 21.4 129.234C23.882 129.51 24.848 129.51 25.951 128.407C26.8214 127.646 27.7616 126.969 28.759 126.385C29.3418 126.03 29.8126 125.517 30.1174 124.907C30.4221 124.296 30.5485 123.611 30.482 122.932C30.409 122.257 30.157 121.701 29.537 121.65C28.2287 121.388 27.0005 120.821 25.952 119.995L22.641 121.649Z"
        fill="#2F2E41"
      />
      <path
        d="M18.706 39.636C21.9076 39.636 24.503 37.0406 24.503 33.839C24.503 30.6374 21.9076 28.042 18.706 28.042C15.5044 28.042 12.909 30.6374 12.909 33.839C12.909 37.0406 15.5044 39.636 18.706 39.636Z"
        fill="#FFB8B8"
      />
      <path
        d="M14.98 35.7429C14.98 35.7429 10.839 43.3619 10.508 43.3619C10.177 43.3619 17.961 45.8459 17.961 45.8459C17.961 45.8459 20.114 38.5589 20.445 37.8959L14.98 35.7429Z"
        fill="#FFB8B8"
      />
      <path
        d="M21.332 44.0811C21.332 44.0811 13.058 39.5301 12.232 39.6681C11.406 39.8061 2.57899 47.5281 2.71699 50.7001C2.95477 53.5273 3.36925 56.3368 3.958 59.1121C3.958 59.1121 4.372 73.7291 5.199 73.8671C6.026 74.0051 5.061 76.4871 5.337 76.4871C5.613 76.4871 24.643 76.4871 24.781 76.0731C24.919 75.6591 21.332 44.0811 21.332 44.0811Z"
        fill="#D0CDE1"
      />
      <path
        d="M25.951 76.832C25.951 76.832 28.571 84.832 26.365 84.554C24.159 84.276 23.193 77.659 23.193 77.659L25.951 76.832Z"
        fill="#FFB8B8"
      />
      <path
        d="M18.918 43.5981C18.918 43.5981 13.818 44.6981 14.643 51.5981C15.468 58.4981 16.987 65.3881 16.987 65.3881L22.087 76.5581L22.639 78.6271L26.362 77.6621L23.604 61.6621C23.604 61.6621 22.639 44.5621 21.398 44.0111C20.616 43.678 19.7658 43.5364 18.918 43.5981Z"
        fill="#D0CDE1"
      />
      <path
        opacity="0.1"
        d="M15.815 65.182L22.158 76.49L16.815 64.572L15.815 65.182Z"
        fill="black"
      />
      <path
        d="M24.481 32.1319L24.5 31.6889L25.381 31.9079C25.3712 31.7658 25.3309 31.6274 25.2627 31.5023C25.1945 31.3772 25.1002 31.2682 24.986 31.1829L25.925 31.1309C25.1372 30.0118 24.1308 29.0639 22.9666 28.3444C21.8023 27.625 20.5044 27.1489 19.151 26.9449C18.1418 26.7466 17.0997 26.7917 16.1114 27.0766C15.1231 27.3614 14.2169 27.8778 13.468 28.5829C12.8167 29.3401 12.3389 30.2306 12.068 31.1919C11.512 32.9379 11.399 35.0199 12.558 36.4399C13.736 37.8829 15.794 38.1649 17.648 38.3399C18.296 38.4563 18.9627 38.411 19.589 38.2079C19.6552 37.5144 19.5664 36.8149 19.329 36.1599C19.2301 35.9573 19.1827 35.7333 19.191 35.5079C19.273 34.9559 20.009 34.8169 20.562 34.8919C21.115 34.9669 21.779 35.0809 22.142 34.6569C22.3426 34.3266 22.4365 33.9425 22.411 33.5569C22.496 32.5219 24.468 32.3509 24.481 32.1319Z"
        fill="#2F2E41"
      />
    </svg>
  );
};

export default NoContent;
