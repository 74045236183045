const generateJavaSnippet = ({ apiName, endpoint, data, operation }) => {
  const url = new URL(`${apiName}/${endpoint}`, process.env.GATSBY_API_ROOT);
  
  Object.entries(data.query).forEach(([key, value]) =>
    url.searchParams.append(key, value)
  );

  const headers = Object.entries(data.header)
    .filter(([key, value]) => !!value || key === 'X-Api-Key')
    .map(([key, value]) => `.header("${key}", "${value}")`);

  const generateJsonLines = (obj) => {
    const lines = [];
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        lines.push(`    \\"${key}\\": {`);
        Object.entries(value).forEach(([k, v]) => {
          lines.push(`      \\"${k}\\": \\"${v}\\"`);
        });
        lines.push('    }');
      } else {
        lines.push(`    \\"${key}\\": \\"${value}\\"`);
      }
    });
    return lines;
  };

  const jsonLines = generateJsonLines(data.body);

  return `import java.net.*;
import java.net.http.*;
import java.util.*;

public class App {
    public static void main(String[] args) throws Exception {
        var httpClient = HttpClient.newBuilder().build();

        var payload = new String[] {
            "{",
${jsonLines.map(line => `            "${line}"`).join(',\n')},
            "}"
        };

        var request = HttpRequest.newBuilder()
            .POST(HttpRequest.BodyPublishers.ofString(String.join("\\n", payload)))
            .uri(URI.create("${url}"))${headers.length ? '\n            ' + headers.join('\n            ') : ''}
            .build();
        
        var response = httpClient.send(request, HttpResponse.BodyHandlers.ofString());
        
        System.out.println(response.body());
    }
}`;
};

export default generateJavaSnippet;