import React from "react";
import styled from "styled-components";

const Button = styled.button`
  border-radius: 0 5px 5px 0;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  transition: 0.275s ease;
  padding: 10px 15px;
  background-color: ${({ isAuth }) => (!isAuth ? "#fd6565" : "#52b474")};
  color: #ffffff;
  cursor: ${({ isAuth }) => (!isAuth ? "not-allowed" : "pointer")};
`;

const SendRequestButton = ({ isAuth, ...props }) => {
  return (
    <Button
      isAuth={isAuth}
      {...props}
    >
      Send
    </Button>
  );
};

export default SendRequestButton;
