import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { getValidators } from "../../../helpers/validators/getValidators";

import { Context } from "../../Layout/index";
import Inputs from "../../../components/Inputs";
import { initState } from "../Endpoint";

import Add from "../../../svg/add";
import Trash from "../../../svg/trash";

import {
  ParamWrapper,
  Field,
  RequiredField,
  InputWrapper,
  ExampleField
} from "./";

const TableRow = ({
  entry: [field, value],
  schema,
  operation,
  parent = "",
  dispatch,
  path,
  method
}) => {
  const { width } = useContext(Context) || {};

  const dispatchName = useMemo(() => {
    if (
      [".", "["].some((e) => field.includes(e)) &&
      !(field.startsWith("[") && field.endsWith("]"))
    ) {
      return `${parent}["${field}"]`;
    }
    return `${parent}${field}`;
  }, [parent, field]);

  const paramColorType = (type) => {
    switch (type) {
      case "body":
        return "#9b5de5";
      case "path":
        return "#ef8354";
      case "query":
        return "#009FFD";
      default:
        return "#ffffff";
    }
  };

  const addArrayElem = () => {
    const initialState = initState(operation);

    dispatch({
      type: "addArrayElem",
      payload: {
        name: `${schema.in || "body"}.${parent}${field}`,
        value: initialState[schema.in || "body"]?.[field]?.[0] ?? ""
      }
    });
  };

  const removeArrayElem = () => {
    // eslint-disable-next-line
    const index = parseInt(field.replace(/[\[\]]/g, ""), 10);

    dispatch({
      type: "removeArrayElem",
      payload: {
        name: `${schema.in || "body"}.${parent}`,
        value: index
      }
    });
  };

  return (
    <Container
      colorType={
        schema.in === "path" ? paramColorType(schema.in || "body") : undefined
      }
    >
      <ParamWrapper>
        <div>
          <Field name="true">{field}</Field>
          {!!schema && (
            <>
              <Field param>{schema.type || ""}</Field>
              <Field colorType={paramColorType(schema.in || "body")} param>{`(${
                schema.in || "body"
              })`}</Field>
              {(operation?.description?.includes?.(`${parent}${field}*`) ||
                schema.required || field === "recipients" || field === "to")  && (
                <RequiredField>REQUIRED</RequiredField>
              )}
            </>
          )}
          {field.startsWith("[") && field.endsWith("]") && (
            <ActionButton onClick={removeArrayElem} type="button">
              <Trash />
            </ActionButton>
          )}
        </div>
        <InputWrapper>
          <Inputs
            label={field}
            param={{
              name: `${parent}${field}`,
              dispatchName,
              schema,
              isRequired:
                operation?.description?.includes?.(`${parent}${field}*`) ||
                schema.required ||
                false
            }}
            dispatch={(payload) => {
              if (
                field.startsWith("[") &&
                field.endsWith("]") &&
                (payload.value === null || payload.value === undefined)
              ) {
                removeArrayElem();
              } else {
                dispatch({ type: schema.in || "body", payload });
              }
            }}
            validators={getValidators(
              schema,
              operation?.description?.includes?.(`${parent}${field}*`) ||
                schema.required ||
                false
            )}
            value={value}
          />
          {width > 565 && !!schema && (
            <ExampleField>
              {schema.example ? `Example: ${schema.example}` : ""}
            </ExampleField>
          )}
        </InputWrapper>
      </ParamWrapper>
      {!!schema && (
        <Field
          dangerouslySetInnerHTML={{
            __html: schema.description
          }}
        />
      )}
      {typeof value === "object" &&
        !Array.isArray(value) &&
        Object.entries(value)
          .filter((e) => schema?.properties?.[e[0]])
          .map((entry) => {
            return (
              <TableRow
                key={`${path}-${method}-${parent}${field}.${entry[0]}`}
                entry={entry}
                schema={
                  schema?.properties?.[entry[0]] ||
                  schema?.additionalProperties ||
                  {}
                }
                operation={operation}
                parent={`${parent}${field}.`}
                dispatch={dispatch}
              />
            );
          })}

      {typeof value === "object" && Array.isArray(value) && (
        <>
          {value.map((entry, idx) => (
            <TableRow
              key={`${path}-${method}-${parent}${field}[${idx}]`}
              entry={[`[${idx}]`, entry]}
              schema={schema.items || {}}
              operation={operation}
              parent={`${parent}${field}`}
              dispatch={dispatch}
            />
          ))}
          {value.length < 5 && (
            <ActionButton onClick={addArrayElem} type="button">
              <Add />
            </ActionButton>
          )}
        </>
      )}
    </Container>
  );
};

export default TableRow;

const ActionButton = styled.button`
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background: ${({ theme }) => theme.second};
  border: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) => theme.border};
  padding: 5px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.second};
  box-shadow: ${({ theme }) => theme.boxShadow};
`;
