import React from "react";

import Description from "./Description";
import Note from "./Note";

const CmsComponents = ({ currentPath, setCurrentPath, apiName, anchor }) => {
  return (
    <>
      <Note apiName={apiName} anchor={anchor} />
      <Description
        currentPath={currentPath}
        setCurrentPath={setCurrentPath}
        anchor={anchor}
      />
    </>
  );
};

export default CmsComponents;
