const generateNodejsSnippet = ({ apiName, endpoint, data, operation }) => {
  const url = new URL(`${apiName}/${endpoint}`, process.env.GATSBY_API_ROOT);

  const config = {
    method: operation.toLowerCase(),
    url: url,
    params: Object.keys(data.query).length ? data.query : undefined,
    headers: data.header,
    data: Object.keys(data.body).length ? data.body : undefined,
  };

  const nodeSnippet = `
const axios = require('axios');

const config = ${JSON.stringify(config, null, 2)};

axios(config)
  .then(response => {
    console.log(JSON.stringify(response.data));
  })
  .catch(error => {
    console.error(error);
  });
`;

  return nodeSnippet;
};

export default generateNodejsSnippet;