import React from "react";
import styled, { keyframes } from "styled-components";

const Spinner = () => {
  return (
    <SpinnerBox>
      <Border1>
        <Core />
      </Border1>
      <Border2>
        <Core />
      </Border2>
    </SpinnerBox>
  );
};

const clockwise = keyframes`
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
`;

const Core = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(36, 63, 91);
`;

const Border2 = styled.div`
  width: 60px;
  height: 60px;
  padding: 3px;
  left: -115px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(71, 160, 201);
  transform: rotate(45deg);
  animation: configure-xclockwise 1s ease-in-out 0s infinite alternate;
`;

const Border1 = styled.div`
  width: 60px;
  height: 60px;
  padding: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #52b474;
  animation: ${clockwise} 1s ease-in-out 0s infinite alternate;
`;

const SpinnerBox = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

export default Spinner;
