import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 } from 'uuid'
import "./index.css";

import Switch from "./Switch";

const Toggle = ({ param, value, dispatch, required, width, ...props }) => {
  const [displayValue, setDisplayValue] = useState();

  useEffect(() => {
    setDisplayValue(value);
    // eslint-disable-next-line
  }, [value]);

  return (
    <Wrapper
      color={!props.required ? "#9e9e9e" : props.hasErrors ? "#fd6565" : "#fff"}
    >
      <Container>
        <p>{displayValue?.toString()}</p>
        <Switch
          id={`toggle-${props.label}-${v4()}`}
          checked={value}
          {...props}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & > div:first-child > label {
    font-size: 14px;
    color: ${({ color }) => color};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.body};
  border-radius: 3px;
  height: 30px;
  margin: 10px 0;
  padding: 0 8px;
  width: auto;
  & > p {
    color: ${({ theme }) => theme.text};
    font-size: 14px;
  }
`;

export default Toggle;
