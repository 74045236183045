import React, { useMemo } from "react";
import styled from "styled-components";

import Select from "../Select";

const SortByInput = ({ className, param, value, onChange }) => {
  const fields = useMemo(
    () =>
      param.schema.name
        .replace("sortBy[", "")
        .replace("]", "")
        .split("|")
        .map((e) => ({ label: e, value: e })),
    [param]
  );
  const selectedField = useMemo(
    () => param.name.replace(/[^\]]+\[([^\]]+)\]/, "$1"),
    [param]
  );

  return (
    <Row className={className}>
      <Select
        isParam={true}
        value={{ label: selectedField, value: selectedField }}
        options={fields}
        onChange={(e) =>
          onChange({
            value,
            name: param.dispatchName.replace(
              `[${selectedField}]`,
              `[${e.value}]`
            ),
          })
        }
        param={param}
        required={param.required}
      />
      <Select
        isParam={true}
        value={{ label: value, value: value }}
        options={param.schema.enum.map((e) => ({ label: e, value: e }))}
        onChange={(e) =>
          onChange({
            value: e?.value || '',
            name: param.dispatchName,
          })
        }
        param={param}
        required={param.required}
        isClearable
      />
    </Row>
  );
};

export default SortByInput;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
