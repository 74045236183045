import React, {
  useContext,
  useMemo,
} from "react";
import styled from "styled-components";
import { string, object } from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { v4 } from "uuid";
import Axios from "axios";

import Form from "../../../components/Form";
import { BoxType } from "../../PageElements/index";
import Auth from "../../../components/Auth";
import SendRequestButton from "../../SendRequestButton";

import Add from "../../../svg/add";
import Trash from "../../../svg/trash";

import {
  getOperationColor,
  handleTypeDisplay,
  getName,
  displayMsgCountTable
} from "../../../helpers/utils";
import { Location } from "@gatsbyjs/reach-router";
import BodyTable from "./BodyTable";
import { parseDescription, stateToRequest } from "../Endpoint";
import { Context } from "../../Layout";

const Table = ({
  state,
  dispatch,
  path,
  type,
  operation,
  id,
  location,
  setCurrentPath,
  currentPath,
  setActiveTab,
  setResponse,
  setShowRightPart,
  loading,
  setLoading,
  ...props
}) => {
  const { isAuth, apiKey } = useContext(Context);
  const { parameters } = operation;
  const { pageContext } = { ...props };
  const isCallback = useMemo(
    () => location.pathname.split("/").includes("callback"),
    [location.pathname]
  );

  const data = useStaticQuery(graphql`
    query {
      allApi {
        nodes {
          version
          name
          servers {
            url
          }
        }
      }
    }
  `);

  const apiData = useMemo(
    () =>
      data.allApi.nodes.find(
        (node) => getName(node.name) === location.pathname.split("/")[1]
      ),
    [data, location]
  );

  const baseUrl = useMemo(
    () =>
      `${process.env.GATSBY_API_ROOT}/${getName(apiData.name)}/${
        apiData.version.split(".")[0]
      }`,
    [apiData]
  );

  const properties = useMemo(() => {
    if (operation["requestBody"] !== undefined) {
      const schema = operation.requestBody.content["application/json"].schema;
      if (schema.type === "array") {
        return schema.items.properties;
      } else {
        return schema.properties;
      }
    } else {
      return pageContext?.schemas[1].properties;
    }
  }, [operation, pageContext]);

  const sendRequest = async (url, method, headers, body, query) => {
    const params = Object.entries(query)
      .map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
      .join("&");
    try {
      return await Axios.create({ headers }).request({
        url: url + (params.length ? "?" : "") + params,
        method: method,
        data: body,
      });
    } catch (error) {
      return error.response;
    }
  };

  const handleSendRequest = async (e) => {

    e.preventDefault();
    setLoading(true);

    const {
      header,
      body,
      path: statePath,
      query
    } = stateToRequest(state, type, apiKey);
    const endpoint = currentPath
      .split("/")
      .map((e) => (e.startsWith("{") ? statePath[e.slice(1, e.length - 1)] : e))
      .join("/");
    const url = baseUrl + "/" + endpoint;

    const response = await sendRequest(url, type, header, body, query);
    setResponse(response);
    setActiveTab("request");
    setShowRightPart(true);

    setLoading(false);

    const requestContainer = document.getElementById(`${type}-${path}`);
    if (requestContainer) {
      requestContainer.style.setProperty("flex-basis", "50%");
    }
  };

  if (operation.tags.includes("Callback")) {
    return null;
  }

  return (
    <TableWrapper>
      <FormContainer onSubmit={handleSendRequest}>
        <TitleWrapper
          offsetTop={document.getElementById("layout_content")?.offsetTop ?? 0}
        >
          <Container>
            <div>
              <BoxType color={getOperationColor(type, path)}>
                {handleTypeDisplay(type, path)}
              </BoxType>
              <Url>{!!currentPath && "/" + currentPath}</Url>
            </div>
            <Fixed>
              <Auth />
              <SendRequestButton
                isAuth={isAuth}
                id="sendRequestButton"
                type="submit"
                disabled={!isAuth || loading}
                className="request-live"
              >
                Send Request
              </SendRequestButton>
            </Fixed>
          </Container>
        </TitleWrapper>

        <Wrapper
          key={`table_${type}_${id}`}
          isCallback={isCallback}
          id={`table_${type}_${id}`}
          isEmpty={parameters?.length}
        >
          {["header", currentPath.includes("{") && "path", "query"]
            .filter(Boolean)
            .map((paramType) => (
              <BodyTable
                key={`BodyTable-${paramType}`}
                state={
                  paramType === "path"
                    ? Object.fromEntries(
                        Object.entries(state[paramType]).filter((e) =>
                          currentPath.includes(`{${e[0]}}`)
                        )
                      )
                    : state[paramType]
                }
                schema={operation.parameters.reduce(
                  (acc, { schema, ...curr }) => ({
                    ...acc,
                    [curr.name]: { ...curr, ...schema }
                  }),
                  {}
                )}
                operation={operation}
                dispatch={dispatch}
                path={currentPath}
                method={type}
              />
            ))}
          {!Object.keys(state.body).some((e) => e.startsWith("body-")) ? (
            <BodyTable
              state={state.body}
              schema={properties}
              operation={operation}
              dispatch={dispatch}
              path={currentPath}
              method={type}
            />
          ) : (
            <div>
              {Object.entries(state.body).map(([key, body]) => (
                <div key={key}>
                  {Object.keys(state.body).length > 1 && (
                    <ActionButton
                      type="button"
                      onClick={() =>
                          dispatch({
                          type: "set",
                          payload: {
                            value: Object.fromEntries(
                              Object.entries(state.body).filter(
                                (e) => e[0] !== key
                              )
                            ),
                            name: "body"
                          }
                        })
                      }
                    >
                      <Trash />
                    </ActionButton>
                  )}
                  <BodyTable
                    state={body}
                    schema={properties}
                    operation={operation}
                    dispatch={(e) =>
                      dispatch({
                        type: e.type,
                        payload: {
                          value: e.payload.value,
                          name: `${key}.${e.payload.name}`
                        }
                      })
                    }
                    path={currentPath}
                    method={type}
                  />
                </div>
              ))}
              {Object.keys(state.body).length < 5 && (
                <ActionButton
                  type="button"
                  onClick={() =>
                    dispatch({
                      type: "set",
                      payload: {
                        value: {
                          ...state.body,
                          [`body-${v4()}`]: parseDescription(operation)
                        },
                        name: "body"
                      }
                    })
                  }
                >
                  <Add />
                </ActionButton>
              )}
            </div>
          )}
          {displayMsgCountTable(apiData, type, path)}
        </Wrapper>
      </FormContainer>
    </TableWrapper>
  );
};

const Fixed = styled.div`
  flex-shrink: 0;
  justify-content: flex-end;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 41px;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    flex-grow: 1;
    min-width: 0;
  }
`;

const ActionButton = styled.button`
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  background: ${({ theme }) => theme.second};
  border: none;
  margin: 10px 0px;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

const Url = styled.div`
  margin: 0 10px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
  min-width: 0;
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #52b474;
    border-radius: 5px;
  }
`;

const FormContainer = styled(Form)`
  width: 100%;
`;

const TableWrapper = styled.div`
  position: relative;
  height: auto;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: ${({ offsetTop }) => `${offsetTop + 10}px`};
  background: ${({ theme }) => theme.second};
  opacity: 0.9;
  z-index: 10;
  backdrop-filter: blur(20px);
  border-radius: 5px;
  padding-left: 15px;
  box-shadow: ${({ theme }) => theme.boxShadow};

  .first-row {
    margin-right: 15px;
  }
  .second-row {
    justify-content: end;
    align-items: normal;
  }
`;

export const ParamWrapper = styled.div`
  flex-wrap: wrap;
  & > div:first-child {
    display: flex;
    margin: 15px 0;
    height: auto;
    align-items: center;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  & p:not(:last-child) {
    margin-right: 5px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: ${({ isCallback }) => isCallback && "unset !important"};
`;

export const RequiredField = styled.p`
  font-weight: 500;
  font-size: 10px;
  color: #fd6565;
  display: flex;
  align-items: center;
`;

export const ExampleField = styled.p`
  font-size: 12px;
  display: flex;
  align-items: center;
  font-style: italic;
  justify-content: end;
`;

export const Field = styled.p`
  font-weight: 400;
  font-size: ${({ param }) => (param ? "12px" : "14px")};
  font-style: ${({ param }) => (param ? "italic" : "normal")};
  color: ${({ theme, colorType }) => colorType || theme.text};
  line-height: 25px;
  background-color: ${({ theme, name }) => name && theme.body};
  padding: ${({ name }) => name && "3px 5px"};
  border-radius: 3px;
  align-items: center;
  display: block;
  height: fit-content;
  padding: 0 10px;
  & > span {
    font-weight: 900;
  }
  & > a {
    color: #52b474;
  }
  & > #button {
    cursor: pointer;
    color: #52b474;
    font-weight: 400;
    text-decoration: underline;
  }
`;

Table.propTypes = {
  path: string.isRequired,
  type: string.isRequired,
  operation: object.isRequired
};

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <Table {...locationProps} {...props} />}
  </Location>
);
