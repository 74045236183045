import React, { useContext } from "react";
import { Location } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import { Table } from "antd";
import { Context } from "../../../components/Layout/index";
import Note from "../../CmsComponents/Note";
import "antd/lib/table/style/index.css";

import "../../../themes/css/index.css";

import Info from "../../../svg/info.svg";
import InfoDark from "../../../svg/info-dark.svg";

const dataSource = [
  {
    key: "1",
    status: "INSUFFICIENT_CREDIT",
    description: `Insufficient funds available, for a PRE_PAY <a href="https://dev.smsmode.com/commons/v1/organisation" target="_blank" rel="noopener noreferrer">Organisation</a>.`
  },
  {
    key: "2",
    status: "DAILY_LIMIT_EXCEEDED",
    description: `The Channel daily limit has been exceeded, this limit is configurable with <a href="https://dev.smsmode.com/commons/v1/channel" target="_blank" rel="noopener noreferrer">Channel</a> API.`
  },
  {
    key: "3",
    status: "ORGANISATION_MONTHLY_LIMIT_EXCEEDED",
    description: `The <a href="https://dev.smsmode.com/commons/v1/organisation" target="_blank" rel="noopener noreferrer">Organisation</a> monthly limit has been exceeded, create a <a href="https://ticket.smsmode.com" target="_blank" rel="noopener noreferrer">ticket</a> to ask limit adjustment.`
  },
  {
    key: "4",
    status: "SPAM",
    description: `Message tagged as spam, create a <a href="https://ticket.smsmode.com" target="_blank" rel="noopener noreferrer">ticket</a> to ask further information about restriction or provide an authorization to send specific sender ID (from).`
  },
  {
    key: "5",
    status: "INVALID_PHONE_NUMBER",
    description: "Not a phone number."
  },
  {
    key: "6",
    status: "BLACKLISTED",
    description: "Message to a blacklisted recipient, who already has unsubscribe from marketing communications."
  },
  {
    key: "7",
    status: "EXPIRED",
    type: "Final",
    description:
      "The message did not receive a final delivery status within five days and has been automatically expired by the platform."
  }
];

const columns = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status"
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (html) => {
      return <Container dangerouslySetInnerHTML={{ __html: html }} />;
    }
  }
];

const SecondStatusTable = ({ margin, commonsNote }) => {
  const { width, currentTheme } = useContext(Context) || {};

  return (
    <MaxSize>
      <Wrapper width={width} style={{ marginTop: width > 1360 && margin }}>
        <p>The status.detail field list is described below:</p>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
        <StaticNote>
          <div>{currentTheme === "light" ? <Info /> : <InfoDark />}</div>
          <p>
            UNDELIVERABLE could also be triggered by an Anti spam error.{" "}
            <a
              href="https://support.smsmode.com/en/base-de-connaissance/anti-spam-error-sending"
              target="_blank"
              rel="noopener noreferrer"
            >
              Why do I get an "Anti-spam error"
            </a>
          </p>
        </StaticNote>
      </Wrapper>
      {commonsNote && (
        <Note
          style={{ maxWidth: "850px" }}
          anchor={"bottom_cms_table_consumption_statuses"}
        />
      )}
    </MaxSize>
  );
};

const StaticNote = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.second};
  border-radius: 5px;
  padding: 15px;
  margin-top: 30px;
  align-items: center;
  & > div {
    margin-right: 5px;
  }
  & > p {
    font-size: 14px;
    line-height: 25px;
    & > #button {
      cursor: pointer;
    }
  }
  & > p > span {
    font-weight: 900;
  }
  & > p > .italic {
    font-style: italic;
    font-weight: normal;
  }
  & > p > a {
    color: #52b474;
  }
`;

const Container = styled.div`
& > a {
  color: #52b474;

}`;
const MaxSize = styled.div`
  max-width: 800px;
`;

const Wrapper = styled.div`
  margin: 60px 0px 20px;
  width: 100%;

  & > p {
    font-size: 14px;
    padding-bottom: 20px;
    line-height: 25px;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: ${({ theme }) => theme.border} !important;
  }

  .ant-table-cell,
  .ant-table {
    color: ${({ theme }) => theme.text} !important;
  }

  .ant-table-content {
    overflow-x: auto !important;
  }

  @media (max-width: 1360px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      font-size: 10px;
    }
  }
`;

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <SecondStatusTable {...locationProps} {...props} />}
  </Location>
);
