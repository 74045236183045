import React from "react";
import TableRow from "./TableRow";
import styled from "styled-components";

const categorizeParams = (params, schema) => {
  return params.reduce((acc, param) => {
    const paramName = param[0];
    const schema_tmp = paramName.startsWith('sortBy[')
      ? schema[Object.keys(schema).find(e => e.startsWith('sortBy['))]
      : schema[paramName];
    const paramType = schema_tmp?.in || "body";

    if (!acc[paramType]) acc[paramType] = [];
    acc[paramType].push({ field: paramName, value: param[1], schema: schema_tmp });

    return acc;
  }, {});
};

const BodyTable = ({ state, schema, operation, dispatch, path, method }) => {
  const categorizedParams = categorizeParams(Object.entries(state), schema);

  return (
    <>
      {Object.entries(categorizedParams).map(([type, params]) => (
        <ParamSection key={type}>
          <ParamSectionTitle>{`${type.toUpperCase()} PARAMS`}</ParamSectionTitle>
          {params.map((param) => (
            <TableRow
              key={`${path}-${method}-${param.field}`}
              entry={[param.field, param.value]}
              schema={param.schema}
              operation={operation}
              dispatch={dispatch}
              path={path}
              method={method}
            />
          ))}
        </ParamSection>
      ))}
    </>
  );
};

export default BodyTable;

const ParamSection = styled.div`
  margin-bottom: 20px;
`;

const ParamSectionTitle = styled.h3`
  margin: 10px;
  margin-top: 15px;
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  font-size: 14px;
`;
