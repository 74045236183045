import React, { useContext } from "react";

import { Context } from "../components/Layout/index";

const Add = () => {
  const { currentTheme } = useContext(Context) || {};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      class="__web-inspector-hide-shortcut__"
    >
      <path
        d="M16 11H13V8H11V11H8V13H11V16H13V13H16V11Z"
        fill={currentTheme === "light" ? "#444" : "#fff"}
        fillOpacity="0.6"
      />
      <path
        d="M19 5H5V19H19V5ZM18 18H6V6H18V18Z"
        fill={currentTheme === "light" ? "#444" : "#fff"}
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default Add;
// fill={currentTheme === "light" ? "#444" : "#BDBDBD"}
