import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import _ from "lodash";
import { v4 } from "uuid";

import StandaloneSelect from "../StandaloneSelect";
import { parseDescription } from "./Endpoint";

const ExamplesSelect = ({
  operation,
  exampleData,
  value,
  onChange,
  dispatch,
}) => {
  const examples = useMemo(
    () =>
      (operation === "post" || operation === "patch") &&
      exampleData.additionalData[operation].requestBody.content[
        "application/json"
      ].examples,
    [operation, exampleData]
  );

  const options = useMemo(
    () =>
      examples
        ? Object.entries(examples).map(([key, value]) => ({
            value: key,
            label: value.summary,
          }))
        : [],
    [examples]
  );

  const handleChange = useCallback((e) => {
    if (!e) 
      return
    onChange(e);
    if (
      e.value &&
      examples[e.value]
    ) {
      const newState = parseDescription(exampleData.additionalData[operation]);
      const example = examples[e.value].value;
      if (Array.isArray(example)) {
        dispatch({
          type: "set",
          payload: {
            name: "body",
            value: example.reduce(
              (acc, curr) => ({
                ...acc,
                [`body-${v4()}`]: _.merge(
                  parseDescription(exampleData.additionalData[operation]),
                  curr
                ),
              }),
              {}
            ),
          },
        });
      } else {
        dispatch({
          type: "set",
          payload: {
            name: "body",
            value: _.merge(newState, examples[e.value].value),
          },
        });
      }
    }
  }, [dispatch, exampleData, examples, onChange, operation]);

  useEffect(() => {
    if (!value) {
      handleChange(options[0])
    }
  }, [handleChange, value, options])

  return (
    <SelectWrapper>
      <StandaloneSelect
        color="#fff"
        options={options}
        value={value}
        onChange={handleChange}
      />
    </SelectWrapper>
  );
};

export default ExamplesSelect;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  margin-bottom: 10px;
`;
