import React from "react";
import styled from "styled-components";
import { object, string } from "prop-types";
import { Location } from "@gatsbyjs/reach-router";
import { sortMethods } from "../../helpers/utils";

import Endpoint from "./Endpoint";

const ParamTables = ({ section, location, ...props }) => {
  const isCallback = location.pathname.split("/").includes("callback");

  const operations =
    (section &&
      Object.keys(section.additionalData).filter(
        (op) => op !== "tags" && op !== "summary"
      )) ||
    [];

  return (
    <Section isCallback={isCallback} {...props}>
      {sortMethods(operations).map((op) => (
        <Endpoint
          key={`${section.path}-${op}`}
          section={section}
          op={op}
          {...props}
        />
      ))}
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 70px;
`;

ParamTables.propTypes = {
  section: object,
  id: string
};

//eslint-disable-next-line
export default (props) => (
  <Location>
    {(locationProps) => <ParamTables {...locationProps} {...props} />}
  </Location>
);
