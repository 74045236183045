import React, { useContext } from "react";
import ReactSelect, { components } from "react-select";
import styled from "styled-components";
import { bool } from "prop-types";

import { Context } from "../../../components/Layout/index";

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>{data.label}</components.SingleValue>
);

const Option = ({ data, ...props }) => (
  <components.Option {...props}>{data.label}</components.Option>
);

const Select = ({ isParam, param, dispatch, required, ...props }) => {
  const { currentTheme } = useContext(Context) || {};

  const customStyle = {
    control: () => ({
      cursor: "pointer",
      height: 30,
      minHeight: 20,
      fontSize: 16,
      fontWeight: 400,
      display: "flex",
      color: "#444",
      backgroundColor: currentTheme === "light" ? "#edeeef" : "#082429",
      width: 175,
      marginBottom: isParam && -15,
      marginLeft: 0,
      borderRadius: "3px",
      minWidth: "100%",
      margin: isParam ? "10px 0" : 0
    }),

    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
      zIndex: 11
    }),
    singleValue: (base) => ({
      ...base,
      color: currentTheme === "dark" ? "#edeeef" : "#082429",
      display: "flex",
      textAlign: "start",
      height: 20,
      fontSize: 14,
      alignItems: "center",
      backgroundColor: currentTheme === "light" ? "#edeeef" : "#082429"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#444",
      margin: "5px",
      width: "calc(100% - 10px)",
      cursor: "pointer",
      borderRadius: "5px",
      display: "flex",
      fontSize: 14,
      "&:hover": {
        backgroundColor: "#52b4743d"
      }
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      transform: isParam ? "rotate(0.5turn)" : "rotate(0)",
      borderRadius: "3px",
      border: 0,
      color: currentTheme === "light" ? "#edeeef" : "#082429",
      transition: "0.275s ease",
      "&:hover": {
        backgroundColor: "#52b4743d"
      }
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none"
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: isParam ? 0 : "2px 8px"
    }),
    input: (provided) => ({
      ...provided,
      color: currentTheme === "light" ? "#edeeef" : "#082429"
    })
  };

  return (
    <Wrapper
      color={!props.required ? "#9e9e9e" : props.hasErrors ? "#fd6565" : "#fff"}
    >
      <StyledSelect
        param={isParam}
        styles={customStyle}
        escapeClearsValue={false}
        isClearable={false}
        isSearchable={isParam ? true : false}
        noOptionsMessage={() => "N/A"}
        // isDisabled={!disabled}
        menuPlacement={isParam ? "top" : "bottom"}
        components={{
          SingleValue,
          Option
        }}
        {...props}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 175px;
  }
  & > div > label {
    font-size: 14px;
    color: ${({ color }) => color};
  }
`;

const StyledSelect = styled((props) => <ReactSelect {...props} />)`
  min-width: 80px;
`;

Select.propTypes = {
  method: bool
};

Select.defaultProps = {
  method: false
};

export default Select;
