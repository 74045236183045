import React from "react";
import Slider from "@material-ui/core/Slider";
import styled from "styled-components";

import { withStyles } from "@material-ui/core/styles";

const CustomSlider = withStyles({
  root: {
    color: "#52b474",
    height: 3,
    maxWidth: "calc(100% - 3px)",
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "#52b474",
    border: "2px solid #52af77",
  },
  track: {
    height: 1,
    borderRadius: 4,
  },
  rail: {
    height: 1,
    borderRadius: 4,
  },
  markLabel: {
    color: "#52b474",
  },
  mark: {
    height: 6,
    width: 6,
    borderRadius: "50%",
    marginTop: "-3px",
    marginLeft: "-3px",
  },
  valueLabel: {
    left: 'calc(-50% - 8px)',
  },
})(Slider);

const marks = [
  {
    value: 10,
    scaledValue: 10,
  },
  {
    value: 20,
    scaledValue: 20,
  },
  {
    value: 50,
    scaledValue: 50,
  },
  {
    value: 100,
    scaledValue: 100,
  },
];

const RangeSlider = ({ required, hasErrors, defaultValue, onChange, sliderProps }) => {
  const classes = withStyles();


  return (
    <Wrapper
      color={
        !required
          ? "#9e9e9e"
          : hasErrors
          ? "#fd6565"
          : "#52b474"
      }
      className={classes.root}
    >
      <Container>
        <CustomSlider
          {...sliderProps}
          defaultValue={defaultValue}
          aria-labelledby="discrete-slider-custom"
          valueLabelDisplay="auto"
          marks={marks}
          onChange={onChange}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 175px;
  & > div {
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & > div > label {
    font-size: 14px;
    color: ${({ color }) => color};
  }
`;

const Container = styled.div`
  background: ${({ theme }) => theme.body};
  height: 30px;
  margin: 10px 0;
  padding: 0 10px;
  border-radius: 3px
`;

export default RangeSlider;
