import React from "react";
import withValidators from "../../../helpers/withValidators";

import { string } from "prop-types";
import styled from "styled-components";

const Input = React.forwardRef((props, ref) => {
  return (
    <Wrapper
      color={!props.required ? "#9e9e9e" : props.hasErrors ? "#fd6565" : "#fff"}
    >
      <InputStyle ref={ref} type="text" {...props} />
    </Wrapper>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 14px;
  }
  & > div > label {
    font-size: 14px;
    color: ${({ color }) => color};
  }
`;

const InputStyle = styled.input`
  width: 100%;
  max-width: 175px;
  height: 30px;
  font-size: 18px;
  padding: 5px;
  margin: 10px 0;
  background: ${({ theme, hasErrors }) => hasErrors ? '#fd65654d' : theme.body};
  color: ${({ hasErrors, theme }) => hasErrors ? '#fd6565' : theme.text};
  font-size: 14px;
  border: none;
  border-radius: 3px;
  outline: none;
  padding-left: 8px;
  ::placeholder {
    color: ${({ hasErrors, theme }) => hasErrors ? '#fd6565' : theme.text};
  }
`;

Input.propTypes = {
  label: string
};

Input.defaultProps = {
  label: ""
};

export default withValidators(Input);
