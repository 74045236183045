/* eslint-disable import/prefer-default-export */

import { required } from "./required";
import { isUuid } from "./isUuid";
import { regex } from "./regex";
import { isInteger } from "./isInteger";

const defaultOptions = {
  checkOnMount: false,
  checkOnChange: true,
  fieldToCheck: "value",
};

export class Validators {
  validator = () => {};
  options = defaultOptions;

  constructor(validator, options = defaultOptions) {
    this.validator = validator;
    this.options = { ...defaultOptions, ...options };
  }

  static required() {
    return new Validators(required());
  }
  static isUuid() {
    return new Validators(isUuid());
  }
  static regex(reg) {
    return new Validators(regex(reg));
  }
  static isInteger(reg) {
    return new Validators(isInteger());
  }
}
