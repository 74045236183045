import React from "react";

import Input from "./Input";
import Select from "./Select";
import Date from "./DatePicker";
import Toggle from "./Toggle";
import RangeSlider from "./RangeSlider";
import ObjectInput from './ObjectInput';
import SortByInput from './SortByInput';

const Inputs = ({ label, param, dispatch, value, validators, ...props }) => {
  const getInput = (schema) => {
    switch (schema?.type) {
      case "boolean":
        return (
          <Toggle
            label={param.name}
            value={value}
            dispatch={() => dispatch}
            onChange={(e) =>
              dispatch({
                value: !value,
                name: param.dispatchName
              })
            }
            param={param}
            required={param.isRequired}
          />
        );
      case "integer":
        if (schema.hasOwnProperty("minimum") && schema.hasOwnProperty("maximum")) {
          return (
            <RangeSlider
              label={param.name}
              value={value || param.schema.default}
              onChange={(e, newValue) =>
                dispatch({
                  value: newValue,
                  name: param.dispatchName
                })
              }
              dispatch={dispatch}
              param={param}
              defaultValue={parseInt(param?.schema?.default) || 0}
              required={param.required}
              min={param?.schema?.minimum}
              data-testid="Input-RangeSlider"
            />
          );
        } else {
          return (
            <Input
              value={value}
              onChange={(e) =>
                dispatch({
                  value: e.target.value,
                  name: param.dispatchName
                })
              }
              validators={validators}
              type={"number"}
              dispatch={dispatch}
              param={param}
              required={param.required}
              min="1"
              data-testid="Input-Number"
            />
          );
        }
      case "string":
        if (param.name.startsWith('sortBy[')) {
          return (
            <SortByInput param={param} value={value} onChange={dispatch} />
          )
        } else if (schema.format === "date-time" || param.name.toLowerCase().includes("date")) {
          return (
              <Date
                onChange={(date) => {
                  dispatch({
                    value: date,
                    name: param.dispatchName
                  })
                }}
                selected={value}
                placeholderText="MM/DD/YYYY"
                dispatch={dispatch}
                value={value}
                param={param}
                data-testid="Input-Date"
              />
          );
        } else if (schema.hasOwnProperty("enum")) {
          const options = schema["enum"].map((item) => ({
            label: item,
            value: item
          }));
          return (
            <Select
              isParam={true}
              value={{ label: value, value: value }}
              options={options}
              onChange={(e) =>
                dispatch({
                  value: e?.value,
                  name: param.dispatchName
                })
              }
              dispatch={dispatch}
              param={param}
              required={param.required}
              isClearable={!param.isRequired}
              data-testid="Input-Select"
            />
          );
        } else {
          return (
            <Input
              value={value}
              onChange={(e) => {
                dispatch({
                  value: e.target.value,
                  name: param.dispatchName
                });
              }}
              validators={validators}
              type={"text"}
              dispatch={dispatch}
              param={param}
              required={param.required}
              data-testid={"Input-String"}
              name={param.name}
            />
          );
        }
      case 'object':
        if (param.schema.hasOwnProperty('properties')) {
          return null
        }
        return (
          <ObjectInput
            value={value}
            onChange={(e) =>
              dispatch({
                value: e,
                name: param.dispatchName
              })
            }
          />
        )
      default:
        console.log("Undefined type");
    }
  };

  return <>{getInput(param && param?.schema)}</>;
};


export default Inputs;
